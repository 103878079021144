import * as React from "react";
import clsx from "clsx";

import "./index.css";

enum AnimationState {
  inProgress,
  complete,
}

interface IProps {
  className?: string;
  colorClassName?: string;
  isVisible: boolean;
}

interface IState {
  animation: AnimationState;
}

export class Loader extends React.Component<IProps, IState> {
  constructor(props: IProps, context: any) {
    super(props, context);

    this.state = {
      animation: props.isVisible
        ? AnimationState.inProgress
        : AnimationState.complete,
    };
  }

  private startAnimation() {
    this.setState((state) => ({
      ...state,
      animation: AnimationState.inProgress,
    }));
  }

  private completeAnimation() {
    this.setState((state) => ({
      ...state,
      animation: AnimationState.complete,
    }));
  }

  public componentDidUpdate(_nextProps: IProps, _nextState: IState) {
    const { isVisible } = this.props;
    const { animation } = this.state;

    if (isVisible && animation === AnimationState.inProgress) {
      return;
    }

    if (isVisible === false && animation === AnimationState.complete) {
      return;
    }

    isVisible ? this.startAnimation() : this.completeAnimation();
  }

  public render() {
    const { className, colorClassName } = this.props;
    const { animation } = this.state;

    return (
      <div
        className={clsx(
          "transition-opacity duration-700 flex items-center justify-center w-full loader-wrapper",
          className
            ? className
            : "fixed top-0 bottom-0 left-0 right-0 max-w-4xl lg:max-w-3xl  xl:max-w-4xl md:left-auto md:right-auto md:-m-4 z-50",
          colorClassName
            ? colorClassName
            : "bg-gradient-to-r from-blue-25 to-blue-50",
          animation === AnimationState.complete
            ? "opacity-0 pointer-events-none"
            : "opacity-100 pointer-events-auto"
        )}
      >
        <div className="flex-1 loader-animation-wrapper">
          <div
            className={clsx(
              "h-2 bg-blue-200 loader-line",
              animation === AnimationState.complete
                ? "loader-complete-animation"
                : animation === AnimationState.inProgress
                ? "loader-in-progress-animation rounded-2xl"
                : null
            )}
          />
        </div>
      </div>
    );
  }
}
