import * as React from "react";

import clsx from "clsx";

interface IProps {
  title: string;
  className?: string;
  isDisabled?: boolean;
  onClick: () => void;
}

export const PrimaryButton = (props: IProps) => {
  const { className, title, isDisabled, onClick } = props;
  return (
    <button
      title={title}
      className={clsx(
        "px-12 py-2 font-semibold text-white rounded-full transition-opacity duration-300 hover:opacity-100 whitespace-nowrap overflow-ellipsis focus:outline-none",
        isDisabled ? "opacity-50 pointer-events-none" : "opacity-90",
        className ? className : "bg-blue-400"
      )}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
