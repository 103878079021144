import { isBrowser } from "../gatsby/index";

export  enum SupportedLanguages {
  de = "de",
  en = "en",
}

export interface ICollection<T> {
  [SupportedLanguages.en]: T;
  [SupportedLanguages.de]: T;
}

export const getTranslation = <T>(
  collection: ICollection<T>
): T => {
  if (isBrowser() === false) {
    return {} as T;
  }

  const currentLang = navigator.language || (navigator as any).userLanguage;

  const isGerman =
    ["de", "de-CH", "de-AT"].find(
      (lang) => currentLang.toLowerCase().indexOf(lang.toLowerCase()) !== -1 
    ) != null;

  return isGerman
    ? collection[SupportedLanguages.de]
    : collection[SupportedLanguages.en];
};

