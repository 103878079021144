import { getUrlParams, isBrowser } from "../common/utils/gatsby/index";
import { MessageType } from "../components/query-params-notification/index";

export const getToken = (): string | null => {
  const params = getUrlParams();
  return params.get("token");
};

export const redirectWithMessageType = (type: MessageType) => {
  if (isBrowser() === false) {
    return;
  }
  
  window.location.href = `/auth?${type}=1`;
};
