const createRequest = <S, T>(url: string, method: string, data: S, token?: string) => {
  return new Promise<T | null>((resolve) => {
    let req = new XMLHttpRequest();
    req.open(method, url, true);

    req.setRequestHeader("Content-type", "application/json");
    
    if (token) {
      req.setRequestHeader("x-token", token);
    }

    req.onreadystatechange = function () {
      if (req.readyState !== 4 && req.status == 200) {
        return;
      }

      if (req.readyState !== 4 && req.status !== 200) {
        return resolve(null);
      }

      if (req.readyState === 4 && req.status !== 200) {
        return resolve(null);
      }

      let stringifiedData = req.response || "";
      if (stringifiedData.length === 0) {
        return resolve({} as T);
      }

      let data = JSON.parse(req.response);
      return resolve(data);
    };

    req.send(JSON.stringify(data));
  });
};

export const get = <S, T>(path: string, data: S, token?: string): Promise<T | null> => {
  const url = `${process.env.SERVICE_URL}${path}`;
  return createRequest(url, "GET", data, token);
};

export const post = <S, T>(path: string, data: S, token?: string): Promise<T | null> => {
  const url = `${process.env.SERVICE_URL}${path}`;
  return createRequest(url, "POST", data, token);
};

export const put = <S, T>(path: string, data: S, token?: string): Promise<T | null> => {
  const url = `${process.env.SERVICE_URL}${path}`;
  return createRequest(url, "PUT", data, token);
};

export const _delete = <S, T>(path: string, data: S, token?: string): Promise<T | null> => {
  const url = `${process.env.SERVICE_URL}${path}`;
  return createRequest(url, "DELETE", data, token);
};
