import * as React from "react";
import { get, post, put, _delete } from "../../utils/http-request/index";

export type OnRequestCallback<S, T> = (
  path: string,
  method: RestMethods,
  data: S,
  continueLoading?: boolean,
  continueLoadingOnError?: boolean
) => Promise<T | null>;

export enum RestMethods {
  get = "GET",
  post = "POST",
  put = "PUT",
  delete = "DELETE",
}

export const useHttpRequest = <S, T>(
  token?: string,
  isInitialLoading?: boolean
): [boolean, OnRequestCallback<S, T>, (isLoading: boolean) => void] => {
  const [isLoading, setLoaderVisible] = React.useState(
    isInitialLoading || false
  );

  const onRequest = async (
    path: string,
    method: RestMethods,
    data: S,
    continueLoading?: boolean,
    continueLoadingOnError?: boolean
  ) => {
    return new Promise<T | null>(async (resolve) => {
      setLoaderVisible(true);

      let next: T | null = null;
      if (method === RestMethods.get) {
        next = await get<S, T>(path, data, token);
      } else if (method === RestMethods.post) {
        next = await post<S, T>(path, data, token);
      } else if (method === RestMethods.put) {
        next = await put<S, T>(path, data, token);
      } else if (method === RestMethods.delete) {
        next = await _delete<S, T>(path, data, token);
      }

      if (next != null && continueLoading === true) {
        return resolve(next);
      }

      if (next == null && continueLoadingOnError === true) {
        return resolve(next);
      }

      setLoaderVisible(false);

      setTimeout(() => resolve(next), 701);
    });
  };

  return [isLoading, onRequest, setLoaderVisible];
};
