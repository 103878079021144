import * as React from "react";
import { Link } from "gatsby";

import Logo from "../../common/assets/logo.svg";
import Graph from "../../common/assets/graph.svg";

interface IProps {
  icon: React.ReactChild;
  children: any;
}

export const Card = (props: IProps) => {
  const { children, icon } = props;

  return (
    <div
      className="container relative flex block max-w-md overflow-hidden bg-white shadow-none transition-shadow duration-500 hover:shadow-lg lg:max-w-4xl lg:flex rounded-3xl"
      style={{ minHeight: 600 }}
    >
      <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col p-6 overflow-hidden lg:relative lg:w-6/12 lg:bg-blue-10">
        <div className="w-32 m-1">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="flex justify-center flex-1 invisible m-24 lg:visible">
          <div className="w-32 h-32 p-6 rounded-full bg-blue-25">{icon}</div>
        </div>
        <div className="absolute -bottom-20 -left-2/4 -right-1/4">
          <Graph />
        </div>
      </div>
      <div
        className="relative z-10 p-6 mt-14 lg:mt-0 lg:w-6/12"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}
      >
        {children}
      </div>
    </div>
  );
};
