import * as React from "react";
import { Loader } from "../../common/components/loader/index";
import { getToken } from "../../utils/index";

import {
  OnRequestCallback,
  useHttpRequest,
} from "../../common/hooks/http-request/index";

interface IProps {
  title: string;
  description: string;
  footer: (
    onRequest: OnRequestCallback<any, any>,
    token: string | null
  ) => React.PropsWithChildren<any>;
  children: React.PropsWithChildren<any>;
}

export const FormLayout = (props: IProps) => {
  const [isLoading, onRequest] = useHttpRequest<any, any>(
    getToken() || undefined
  );

  const token = getToken();

  const { children, footer, title, description } = props;

  return (
    <div className="flex flex-col h-full px-4">
      <div className="text-lg text-4xl text-center">{title}</div>
      <div className="w-4/5 mx-auto my-3 text-center text-md">
        {description}
      </div>
      <div className="flex-1">{children}</div>
      <div className="flex-grow-0 flex-shrink-0 mt-8">
        {footer(onRequest, token)}
      </div>
      <Loader
        className="absolute top-0 bottom-0 left-0 right-0"
        colorClassName="bg-white"
        isVisible={isLoading}
      />
    </div>
  );
};
